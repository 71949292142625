footer {
  padding: 0;
}
footer a,
footer p {
  color: #fff;
}

@media (max-width: 950px) {
  .container {
    max-width: none !important;
  }
  p {
    margin-bottom: 0;
  }
}

.wsnr {
  white-space: nowrap;
}

.radio input {
  height: 24px;
  width: 24px;
}
.radio__input {
  display: none;
}
.radio__input + label {
  padding-left: 35px;
  position: relative;
  margin-right: 20px;
}
.radio__input + label::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: auto;
  left: 0;
  margin: auto;
  width: 25px;
  height: 25px;
  border: 1px solid #c8c8c8;
  border-radius: 50%;
}
.radio__input:checked + label::before {
  border: 0;
  background-color: #fac83c;
}
.radio__input:checked + label::after {
  content: "";
  display: block;
  position: absolute;
  top: 5px;
  left: 9px;
  width: 7px;
  height: 11px;
  transform: rotate(40deg);
  border-bottom: 3px solid #fff;
  border-right: 3px solid #fff;
}

select::-ms-expand {
  display: none;
}

/* checkoutページ周り*/
@media screen and (min-width: 1036px) {
  main.reverse {
    -webkit-box-direction: row-reverse;
    -moz-box-direction: row-reverse;
    -webkit-flex-direction: row-reverse;
    -moz-flex-direction: row-reverse;
    flex-direction: row-reverse;
    display: flex;
  }
  .toggle__cart,
  .breadcrumb {
    display: block;
  }
  .breadcrumb p i {
    margin: 0 5px;
  }
  .toggle__cart p.text__red.text__s {
    display: inline-block;
  }
  .cart__block {
    background-color: #f0f0f0;
    padding: 20px 40px 40px;
    margin-top: 10px;
    border-radius: 4px;
  }
}

@media screen and (min-width: 1036px) {
  .text__left__pc {
    text-align: left;
  }
  .text__right__pc {
    text-align: right;
  }
  .text__center__pc {
    text-align: center;
  }
  .pd__top__off__pc {
    padding-top: 0 !important;
  }
  .pd__bottom__off__pc {
    padding-bottom: 0 !important;
  }
  .mg__top__off__pc {
    margin-top: 0 !important;
  }
  .mg__bottom__off__pc {
    margin-bottom: 0 !important;
  }
}
