html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

div,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

html {
  height: 100%;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  outline: none;
}

a:focus {
  outline: none;
}

a,
a:link,
a:visited,
a:active,
a:hover {
  color: inherit;
  text-decoration: none;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
}

button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  appearance: none;
}

input[type="submit"],
input[type="button"] {
  border-radius: 0;
  -webkit-box-sizing: content-box;
  -webkit-appearance: button;
  appearance: button;
  border: none;
  box-sizing: border-box;
}

input[type="submit"]::-webkit-search-decoration,
input[type="button"]::-webkit-search-decoration {
  display: none;
}

input[type="submit"]::focus,
input[type="button"]::focus {
  outline-offset: -2px;
}

body,
html {
  background-color: #fff;
}

blockquote {
  font-size: 1.125em;
  font-style: normal;
  text-align: center;
  padding: 0 30px;
  margin: 0;
}

blockquote p + cite {
  margin-top: 27.5px;
}

blockquote cite {
  display: block;
  font-size: 0.85em;
  font-weight: 400;
}

blockquote cite::before {
  content: "\2014 \0020";
}

code,
pre {
  font-size: 1em;
}

pre {
  overflow: auto;
}

body,
input,
textarea,
button,
select {
  line-height: 1.5;
  font-family:
    "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
}

p {
  color: #5a5a5a;
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

p:last-child {
  margin-bottom: 0;
}

li {
  list-style: none;
}

ul,
ol {
  margin: 0;
  padding: 0;
}

ol {
  list-style: decimal;
}

a {
  color: #5a5a5a;
  text-decoration: none;
}

a:hover,
a:focus {
  opacity: 0.6;
}

a[href^="tel"] {
  color: inherit;
}

table {
  margin-bottom: 27.5px;
}

th {
  font-weight: 700;
}

th,
td {
  text-align: left;
  padding: 10px 14px;
}

svg:not(:root) {
  overflow: hidden;
}

form {
  margin: 0;
}

fieldset {
  border: 1px solid #e8e9eb;
  margin: 0 0 55px;
  padding: 27.5px;
}

legend {
  border: 0;
  padding: 0;
}

button {
  cursor: pointer;
}

input[type="submit"] {
  cursor: pointer;
}

label {
  display: block;
  margin-bottom: 5px;
}

@media only screen and (max-width: 749px) {
  label {
    font-size: 0.875em;
  }
}

[type="radio"] + label,
[type="checkbox"] + label {
  display: inline-block;
  margin-bottom: 0;
}

label[for] {
  cursor: pointer;
}

input,
textarea,
select {
  border: 1px solid #c8c8c8;
  background-color: #fff;
  color: #000;
  max-width: 100%;
  line-height: 1.2;
  border-radius: 4px;
}

input:focus,
textarea:focus,
select:focus {
  border-color: #cdcfd3;
}

input[disabled],
textarea[disabled],
select[disabled] {
  cursor: default;
  background-color: #f0f0f0;
  border-color: #c8c8c8;
}

textarea {
  min-height: 100px;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-position: right center;
  background-image: url(//cdn.shopify.com/s/files/1/0008/2693/3309/t/10/assets/ico-select.svg?v=15556381834474199855);
  background-repeat: no-repeat;
  background-position: right 10px center;
  line-height: 1.2;
  padding-right: 28px;
  text-indent: 0.01px;
  cursor: pointer;
  padding-top: 8px;
  padding-left: 15px;
  padding-bottom: 8px;
}

@media only screen and (min-width: 750px) {
  select {
    padding-top: 10px;
    padding-left: 18px;
    padding-bottom: 10px;
  }
}

optgroup {
  font-weight: 700;
}

option {
  background-color: #fff;
}

select::-ms-expand {
  display: none;
}

::-webkit-input-placeholder {
  color: #000;
  opacity: 0.6;
}

::-moz-placeholder {
  color: #000;
  opacity: 0.6;
}

:-ms-input-placeholder {
  color: #000;
  opacity: 0.6;
}

::-ms-input-placeholder {
  color: #000;
  opacity: 1;
}

input,
textarea {
  padding: 8px 15px;
}

@media only screen and (min-width: 750px) {
  input,
  textarea {
    padding: 10px 18px;
  }
}

hr {
  margin: 55px 0;
  border: 0;
  border-bottom: 1px solid #e8e9eb;
}

.anygift-gift-ui__container {
  width: 100% !important;
  margin: 20px 0px !important;
  /* 万が一anygiftの「ボタンが複数回初期化されたら、1つ目のボタンのみ表示するように制御 */
  &:not(:first-child) {
    display: none;
  }
}

.anygift-gift-ui__main-button {
  border-radius: 100px !important;
  height: 68px !important;
  padding: 0 36px !important;
}

@media only screen and (max-width: 768px) {
  .anygift-gift-ui__main-button {
    border-radius: 44px !important;
    height: 44px !important;
    padding: 0 20px !important;
  }
}

#EgiftLineup .anygift-gift-ui__main-button {
  border-radius: 44px !important;
  height: 44px !important;
  padding: 0 20px !important;
}

.anygift-about-modal__section > div:nth-of-type(1) img {
  display: none;
}

.anygift-about-modal__section > div:nth-of-type(1)::after {
  content: "";
  display: block;
  width: 100px;
  height: 130px;
  margin: 0 auto;
  background: url(/assets/images/egift/about_01.png) no-repeat center center;
  background-size: contain;
}

.anygift-about-modal__section > div:nth-of-type(2) img {
  display: none;
}

.anygift-about-modal__section > div:nth-of-type(2)::after {
  content: "";
  display: block;
  width: 100px;
  height: 130px;
  margin: 0 auto;
  background: url(/assets/images/egift/about_02.png) no-repeat center center;
  background-size: contain;
}

.anygift-about-modal__section > div:nth-of-type(3) img {
  display: none;
}

.anygift-about-modal__section > div:nth-of-type(3)::after {
  content: "";
  display: block;
  width: 100px;
  height: 130px;
  margin: 0 auto;
  background: url(/assets/images/egift/about_03.png) no-repeat center center;
  background-size: contain;
}

.anygift-about-modal__section > div:nth-of-type(4) img {
  display: none;
}

.anygift-about-modal__section > div:nth-of-type(4)::after {
  content: "";
  display: block;
  width: 100px;
  height: 130px;
  margin: 0 auto;
  background: url(/assets/images/egift/about_04.png) no-repeat center center;
  background-size: contain;
}
.swiper-button-disabled {
  opacity: 0 !important;
}

.anygift-order-productーbox__total-price__wrapper {
  position: relative;
  &:after {
    content: "商品価格は1回のみ購入の金額です";
    display: block;
    text-align: right;
    font-size: 12px;
    color: rgb(168 162 158);
  }
}

.anygift-floating-cart {
  display: none;
}

.anygift-top + .anygift-floating-cart {
  display: block;
  z-index: 1;
}

#EgiftAbout {
  #EgiftReceive + div {
    .swiper-button-prev,
    .swiper-button-next {
      background-color: #ff6478;
      width: 40px;
      height: 40px;
      border-radius: 40px;
      color: #fff;
    }
  }
  #EgiftSend + div {
    .swiper-button-prev,
    .swiper-button-next {
      background-color: #ff9b00;
      width: 40px;
      height: 40px;
      border-radius: 40px;
      color: #fff;
    }
  }
  .swiper-button-prev {
    left: 20px;
  }
  .swiper-button-next {
    right: 20px;
  }
  .swiper-button-prev:after,
  .swiper-button-next:after {
    font-size: 14px;
  }
}
