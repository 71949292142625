:root {
  // swiperのスタイル
  --swiper-pagination-color: #ff9b00;
  --swiper-pagination-bullet-inactive-color: #ffffff;
  --swiper-pagination-bullet-inactive-opacity: 1;
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-1 {
  flex: 1;
}

.justify-center {
  justify-content: center;
}

.align-center {
  align-items: center;
}

.border-bottom {
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.m-auto {
  margin: auto;
}

.ml-auto {
  margin-left: auto;
}

.rounded-10px {
  border-radius: 10px;
}

.hidden {
  display: none;
}

@media screen and (max-width: 1035px) {
  .col-px-fix {
    padding-left: 0px;
    padding-right: 0px;
  }

  .col-px-fix-14px {
    padding-left: 14px;
    padding-right: 14px;
  }

  .fix-container-margin {
    margin-left: -28px;
    margin-right: -28px;
  }
}
