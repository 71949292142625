@charset "utf-8";
h1 {
  font-size: 1.5rem; // 24px
}
h2 {
  font-size: 1.25rem; // 20px
}
.wsnr {
  white-space: nowrap;
}

.small {
  font-size: 60%;
}

.sup {
  font-size: 50%;
  vertical-align: text-top;
}

.sub {
  font-size: 50%;
  vertical-align: text-bottom;
}
.text__bold {
  font-weight: bold;
}
.text__normal {
  font-weight: normal;
}

// font- size
.text__xxxl {
  font-size: 3rem; // 48px
}
.text__xxl {
  font-size: 2rem; // 32px
}
.text__xl {
  font-size: 1.5rem; // 24px
}
.text__l {
  font-size: 1.25rem; // 20px
}
.text__m {
  font-size: 1rem; // 16px
}
.text__s {
  font-size: 0.75rem; // 12px
}
.text__xs {
  font-size: 0.625rem; // 10px
}
.text__left {
  text-align: left;
}
.text__right {
  text-align: right;
}
.text__justify {
  text-align: justify;
}
.text__center {
  text-align: center;
}

/*TODO 消す。使用箇所が膨大なので注意 */
.btn {
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: auto;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  padding: 8px 15px;
}

@media only screen and (min-width: 750px) {
  .btn {
    padding: 10px 18px;
  }
}

.btn:hover,
.btn:focus {
  opacity: 0.6;
}

.btn[disabled] {
  cursor: default;
  opacity: 0.5;
}

button.btn,
input[type="submit"].btn,
input[type="button"].btn,
a.btn,
span.btn {
  border: none;
  font-weight: bold;
  &.normal {
    font-weight: normal;
  }
  &:active {
    opacity: 0.6;
  }
  &.inline {
    display: inline-block;
    vertical-align: middle;
    margin-left: 5px;
    margin-right: 5px;
  }
  &.block {
    display: block;
    text-align: center;
  }
  &.radius {
    border-radius: 4px;
  }
  &.round {
    line-height: 44px;
    border-radius: 44px;
    padding: 0 20px;
  }
  &.square {
    line-height: 35px;
    border-radius: 4px;
    padding: 0 15px;
  }
  &.mini {
    line-height: 24px;
    border-radius: 24px;
    font-size: 0.75rem;
    padding: 0 10px;
  }
  &.border__wh {
    border: 1px solid #fff;
    color: #fff;
    &:hover {
      background-color: rgba(255, 255, 255, 0.2);
    }
  }
  &.border__bk {
    border: 1px solid #5a5a5a;
    &:hover {
      background-color: rgba(90, 90, 90, 0.2);
    }
  }
  &.border__blue {
    color: #00508c;
    border: 1px solid #00508c;
    &:hover {
      color: #fff;
      background-color: #003264;
    }
  }
  &.yellow {
    background-color: #fac83c;
    &:hover {
      background-color: #ff9b00;
    }
  }
  &.green {
    background-color: #c8dc3c;
    &:hover {
      background-color: #dcdc78;
    }
  }
  &.blue {
    color: #fff;
    background-color: #00508c;
    &:hover {
      background-color: #003264;
    }
    &.disable {
      opacity: 0.6;
      background-color: #00508c !important;
      cursor: not-allowed;
    }
  }
  &.red {
    background-color: #ff6478;
    color: #fff;
    &:hover {
      background-color: #ff2850;
    }
  }
  &.gray {
    background-color: #f0f0f0;
    &:hover {
      background-color: #c8c8c8;
    }
  }
  &.angle__right {
    i {
      margin-left: 5px;
    }
  }
  &.angle__left {
    i {
      margin-right: 5px;
    }
  }
}

span.tag {
  border: none;
  font-weight: bold;
  &.normal {
    font-weight: normal;
  }
  &.inline {
    display: inline-block;
    vertical-align: middle;
    margin-left: 5px;
    margin-right: 5px;
  }
  &.block {
    display: block;
    text-align: center;
  }
  &.radius {
    border-radius: 4px;
  }
  &.round {
    line-height: 44px;
    border-radius: 44px;
    padding: 0 20px;
  }
  &.square {
    line-height: 35px;
    border-radius: 4px;
    padding: 0 15px;
  }
  &.mini {
    line-height: 24px;
    border-radius: 24px;
    font-size: 0.75rem;
    padding: 0 10px;
    &.square {
      border-radius: 5px;
    }
  }
  &.border__wh {
    border: 1px solid #fff;
    color: #fff;
  }
  &.border__bk {
    border: 1px solid #5a5a5a;
  }
  &.border__blue {
    border: 1px solid #00508c;
  }
  &.yellow {
    background-color: #fac83c;
  }
  &.darkblue {
    background-color: #8cb4dc;
  }
  &.green {
    background-color: #c8dc3c;
  }
  &.green__dark {
    background-color: #0096a0;
  }
  &.blue {
    background-color: #00508c;
    &.disable {
      opacity: 0.6;
      background-color: #00508c !important;
      cursor: not-allowed;
    }
  }
  &.red {
    background-color: #ff6478;
    color: #fff;
  }
  &.gray {
    background-color: #f0f0f0;
  }
}
.mg__top__off {
  margin-top: 0 !important;
}
.mg__top__xs {
  margin-top: 5px !important;
}
.mg__top__s {
  margin-top: 10px !important;
}
.mg__top__m {
  margin-top: 20px !important;
}
.mg__top__l {
  margin-top: 40px !important;
}
.mg__top__xl {
  margin-top: 80px !important;
}
.mg__bottom__off {
  margin-bottom: 0 !important;
}
.mg__bottom__xs {
  margin-bottom: 5px !important;
}
.mg__bottom__s {
  margin-bottom: 10px !important;
}
.mg__bottom__m {
  margin-bottom: 20px !important;
}
.mg__bottom__l {
  margin-bottom: 40px !important;
}
.mg__bottom__xl {
  margin-bottom: 80px !important;
}
.pd__top__off {
  padding-top: 0 !important;
}
.pd__top__xs {
  padding-top: 5px !important;
}
.pd__top__s {
  padding-top: 10px !important;
}
.pd__top__m {
  padding-top: 20px !important;
}
.pd__top__l {
  padding-top: 40px !important;
}
.pd__top__xl {
  padding-top: 80px !important;
}
.pd__bottom__off {
  padding-bottom: 0 !important;
}
.pd__bottom__xs {
  padding-bottom: 5px !important;
}
.pd__bottom__s {
  padding-bottom: 10px !important;
}
.pd__bottom__m {
  padding-bottom: 20px !important;
}
.pd__bottom__l {
  padding-bottom: 40px !important;
}
.pd__bottom__xl {
  padding-bottom: 80px !important;
}
.pd__left__xs {
  padding-left: 5px !important;
}
.bg__white {
  background-color: #fff;
}
.bg__gray {
  background-color: #f0f0f0;
}
.bg__darkgray {
  background-color: #c8c8c8;
}
.bg__darkblue {
  background-color: #8cb4dc;
}
.bg__black {
  background-color: #5a5a5a;
}
.bg__yellow {
  background-color: #fac83c;
}
.bg__yellow__light {
  background-color: #fdfdee;
}
.bg__red {
  background-color: #ff6478;
}
.bg__orange {
  background-color: #f5d9ca;
}
.bg__green {
  background-color: #0096a0;
}
.text__white {
  color: #fff;
}
.text__gray__light {
  color: #f0f0f0;
}
.text__gray {
  color: #c8c8c8;
}
.text__gray__dark {
  color: #828282;
}
.text__black__light {
  color: #6e6e6e;
}
.text__black {
  color: #5a5a5a;
}
.text__black__dark {
  color: #000;
}
.text__yellow__light {
  color: #fff078;
}
.text__yellow {
  color: #fac83c;
}
.text__yellow__dark {
  color: #ff9b00;
}
.text__red__light {
  color: #ffb4be;
}
.text__red {
  color: #ff6478;
}
.text__red__dark {
  color: #ff2850;
}
.text__green__light {
  color: #8cc8dc;
}
.text__green {
  color: #0096a0;
}
.text__green__dark {
  color: #006478;
}
.text__blue__light {
  color: #8cb4dc;
}
.text__blue {
  color: #00508c;
}
.text__blue__dark {
  color: #003264;
}
.text__success {
  color: #0096a0;
}

.clear__sp {
  display: none !important;
}

@media screen and (min-width: 1036px) {
  .text__xxxl__pc {
    font-size: 3rem; // 48px
  }
  .text__xxl__pc {
    font-size: 2rem; // 32px
  }
  .text__xl__pc {
    font-size: 1.5rem;
  }
  .text__l__pc {
    font-size: 1.25rem;
  }
  .text__m__pc {
    font-size: 1rem;
  }
  .text__s__pc {
    font-size: 0.75rem; // 12px
  }
  .text__xs__pc {
    font-size: 0.625rem; // 10px
  }
  .clear__pc {
    display: none !important;
  }
  .clear__sp {
    display: block !important;
    &.inline {
      display: inline !important;
    }
  }
  .text__left__pc {
    text-align: left;
  }
  .text__right__pc {
    text-align: right;
  }
  .text__center__pc {
    text-align: center;
  }
  .pd__top__s__pc {
    padding-top: 10px !important;
  }
  .pd__top__15__pc {
    padding-top: 15px !important;
  }
  .pd__top__off__pc {
    padding-top: 0 !important;
  }
  .pd__bottom__s__pc {
    padding-bottom: 10px !important;
  }
  .pd__bottom__off__pc {
    padding-bottom: 0 !important;
  }
  .mg__top__s__pc {
    margin-top: 10px !important;
  }
  .mg__top__off__pc {
    margin-top: 0 !important;
  }
  .mg__bottom__s__pc {
    margin-bottom: 10px !important;
  }
  .mg__bottom__off__pc {
    margin-bottom: 0 !important;
  }
}

.full__image {
  width: 100%;
  height: auto;
}
