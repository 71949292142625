body {
  font-size: 1rem;
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: none;
  -webkit-font-smoothing: antialiased;
  -webkit-font-smoothing: subpixel-antialiased;
  font-feature-settings: normal;
}

main {
  position: relative;
}

.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 14px;
  padding-left: 14px;
}

.row {
  display: flex;
  -webkit-display: flex;
  -ms-display: flex;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  margin-left: -14px;
  margin-right: -14px;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-left: 14px;
  padding-right: 14px;
}

.col-1 {
  flex: 0 0 8.333333%;
  -webkit-flex: 0 0 8.333333%;
  -ms-flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.col-2 {
  flex: 0 0 16.666667%;
  -webkit-flex: 0 0 16.666667%;
  -ms-flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-3 {
  flex: 0 0 25%;
  -webkit-flex: 0 0 25%;
  -ms-flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.333333%;
  -webkit-flex: 0 0 33.333333%;
  -ms-flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.col-5 {
  flex: 0 0 41.666667%;
  -webkit-flex: 0 0 41.666667%;
  -ms-flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.col-6 {
  flex: 0 0 50%;
  -webkit-flex: 0 0 50%;
  -ms-flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.333333%;
  -webkit-flex: 0 0 58.333333%;
  -ms-flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.col-8 {
  flex: 0 0 66.666667%;
  -webkit-flex: 0 0 66.666667%;
  -ms-flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.col-9 {
  flex: 0 0 75%;
  -webkit-flex: 0 0 75%;
  -ms-flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.333333%;
  -webkit-flex: 0 0 83.333333%;
  -ms-flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.col-11 {
  flex: 0 0 91.666667%;
  -ms-flex: 0 0 91.666667%;
  -webkit-flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.col-12 {
  flex: 0 0 100%;
  -webkit-flex: 0 0 100%;
  -ms-flex: 0 0 100%;
  max-width: 100%;
}

.col-push-1 {
  left: 8.333333%;
}

.col-push-2 {
  left: 16.666667%;
}

.col-push-3 {
  left: 25%;
}

.col-push-4 {
  left: 33.333333%;
}

.col-push-5 {
  left: 41.666667%;
}

.col-push-6 {
  left: 50%;
}

.col-push-7 {
  left: 58.333333%;
}

.col-push-8 {
  left: 66.666667%;
}

.col-push-9 {
  left: 75%;
}

.col-push-10 {
  left: 83.333333%;
}

.col-push-11 {
  left: 91.666667%;
}

.col-push-12 {
  left: 100%;
}

.col-pull-1 {
  right: 8.333333%;
}

.col-pull-2 {
  right: 16.666667%;
}

.col-pull-3 {
  right: 25%;
}

.col-pull-4 {
  right: 33.333333%;
}

.col-pull-5 {
  right: 41.666667%;
}

.col-pull-6 {
  right: 50%;
}

.col-pull-7 {
  right: 58.333333%;
}

.col-pull-8 {
  right: 66.666667%;
}

.col-pull-9 {
  right: 75%;
}

.col-pull-10 {
  right: 83.333333%;
}

.col-pull-11 {
  right: 91.666667%;
}

.col-pull-12 {
  right: 100%;
}

.col-center {
  margin-left: auto;
  margin-right: auto;
}

// smart phone

@media screen and (max-width: 979px) {
  .container {
    padding-left: 28px;
    padding-right: 28px;
  }
  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6 {
    flex: 0 0 50%;
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    max-width: 50%;
  }

  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12 {
    flex: 0 0 100%;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    max-width: 100%;
  }

  .col-offset-1,
  .col-offset-2,
  .col-offset-3,
  .col-offset-4,
  .col-offset-5,
  .col-offset-6 {
    margin-left: 50%;
  }

  .col-offset-7,
  .col-offset-8,
  .col-offset-9,
  .col-offset-10,
  .col-offset-11,
  .col-offset-12 {
    margin-left: 100%;
  }
}

// laptop

@media screen and (min-width: 1036px) {
  .container {
    max-width: 1008px;
  }

  .row {
    margin-left: -14px;
    margin-right: -14px;
  }

  .col-m-1 {
    flex: 0 0 8.333333%;
    -webkit-flex: 0 0 8.333333%;
    -ms-flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-m-2 {
    flex: 0 0 16.666667%;
    -webkit-flex: 0 0 16.666667%;
    -ms-flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-m-3 {
    flex: 0 0 25%;
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    max-width: 25%;
  }

  .col-m-4 {
    flex: 0 0 33.333333%;
    -webkit-flex: 0 0 33.333333%;
    -ms-flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-m-5 {
    flex: 0 0 41.666667%;
    -webkit-flex: 0 0 41.666667%;
    -ms-flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-m-6 {
    flex: 0 0 50%;
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    max-width: 50%;
  }

  .col-m-7 {
    flex: 0 0 58.333333%;
    -webkit-flex: 0 0 58.333333%;
    -ms-flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-m-8 {
    flex: 0 0 66.666667%;
    -webkit-flex: 0 0 66.666667%;
    -ms-flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-m-9 {
    flex: 0 0 75%;
    -webkit-flex: 0 0 75%;
    -ms-flex: 0 0 75%;
    max-width: 75%;
  }

  .col-m-10 {
    flex: 0 0 83.333333%;
    -webkit-flex: 0 0 83.333333%;
    -ms-flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-m-11 {
    flex: 0 0 91.666667%;
    -ms-flex: 0 0 91.666667%;
    -webkit-flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-m-12 {
    flex: 0 0 100%;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    max-width: 100%;
  }

  .col-offset-m-1 {
    margin-left: 8.333333%;
  }

  .col-offset-m-2 {
    margin-left: 16.666667%;
  }

  .col-offset-m-3 {
    margin-left: 25%;
  }

  .col-offset-m-4 {
    margin-left: 33.333333%;
  }

  .col-offset-m-5 {
    margin-left: 41.666667%;
  }

  .col-offset-m-6 {
    margin-left: 50%;
  }

  .col-offset-m-7 {
    margin-left: 58.333333%;
  }

  .col-offset-m-8 {
    margin-left: 66.666667%;
  }

  .col-offset-m-9 {
    margin-left: 75%;
  }

  .col-offset-m-10 {
    margin-left: 83.333333%;
  }

  .col-offset-m-11 {
    margin-left: 91.666667%;
  }

  .col-offset-m-12 {
    margin-left: 100%;
  }
  .order-1 {
    order: 1;
  }
  .order-2 {
    order: 2;
  }

  .justify-content-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
}

@media screen and (min-width: 1336px) {
  .container {
    max-width: 1308px;
  }

  .col-l-1 {
    flex: 0 0 8.333333%;
    -webkit-flex: 0 0 8.333333%;
    -ms-flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-l-2 {
    flex: 0 0 16.666667%;
    -webkit-flex: 0 0 16.666667%;
    -ms-flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-l-3 {
    flex: 0 0 25%;
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    max-width: 25%;
  }

  .col-l-4 {
    flex: 0 0 33.333333%;
    -webkit-flex: 0 0 33.333333%;
    -ms-flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-l-5 {
    flex: 0 0 41.666667%;
    -webkit-flex: 0 0 41.666667%;
    -ms-flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-l-6 {
    flex: 0 0 50%;
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    max-width: 50%;
  }

  .col-l-7 {
    flex: 0 0 58.333333%;
    -webkit-flex: 0 0 58.333333%;
    -ms-flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-l-8 {
    flex: 0 0 66.666667%;
    -webkit-flex: 0 0 66.666667%;
    -ms-flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-l-9 {
    flex: 0 0 75%;
    -webkit-flex: 0 0 75%;
    -ms-flex: 0 0 75%;
    max-width: 75%;
  }

  .col-l-10 {
    flex: 0 0 83.333333%;
    -webkit-flex: 0 0 83.333333%;
    -ms-flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-l-11 {
    flex: 0 0 91.666667%;
    -ms-flex: 0 0 91.666667%;
    -webkit-flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-l-12 {
    flex: 0 0 100%;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    max-width: 100%;
  }
}
