@charset "utf-8";
@import "somastrap3.0";
@import "common";

$GRAY: #5a5a5a;
$YELLOW: #fac83c;
$LIGHTGRAY: #c8c8c8;
$RED: #ff6478;

body {
  overflow-x: hidden;
}

body,
button {
  color: $GRAY;
}

h2 {
  margin-bottom: 20px;
}

.section {
  padding-top: 20px;
  padding-bottom: 20px;

  &.bg {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.radio__input,
.not_show {
  display: none;
}

.radio__input + label {
  padding-left: 35px;
  position: relative;
  margin-right: 10px;
  display: inline-block;
  vertical-align: top;
}

.radio__input + label::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: auto;
  left: 0;
  width: 25px;
  height: 25px;
  border: 1px solid #c8c8c8;
  border-radius: 50%;
}

.radio__input:checked + label::before {
  border: 0;
  background-color: $YELLOW;
}

.radio__input:checked + label::after {
  content: "";
  display: block;
  position: absolute;
  top: 5px;
  left: 9px;
  width: 7px;
  height: 11px;
  transform: rotate(40deg);
  border-bottom: 3px solid #fff;
  border-right: 3px solid #fff;
}

.checkbox__input {
  display: none;
}

.checkbox__input + label {
  padding-left: 35px;
  position: relative;
  margin-right: 20px;
}

.checkbox__input + label::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 25px;
  height: 25px;
  border: 1px solid #c8c8c8;
  border-radius: 4px;
}

.checkbox__input:checked + label::before {
  border: 0;
  background-color: $YELLOW;
}

.checkbox__input:checked + label::after {
  content: "";
  display: block;
  position: absolute;
  top: 3px;
  left: 9px;
  width: 7px;
  height: 11px;
  transform: rotate(40deg);
  border-bottom: 3px solid #fff;
  border-right: 3px solid #fff;
}

.gray__table {
  border: 1px solid #c8c8c8;
  border-radius: 4px;
}

.table {
  display: table;
  width: 100%;

  dt,
  dd {
    display: table-cell;
    vertical-align: text-top;
    padding-top: 14px;
    padding-bottom: 14px;
  }

  dt {
    width: 70px;
    padding-left: 20px;
  }

  dd {
    padding-left: 20px;
    padding-right: 20px;
    width: calc(100% - 70px);

    &.input {
      padding-top: 0;
      padding-bottom: 0;
      vertical-align: middle;
    }
  }

  &.input {
    dt,
    dd {
      vertical-align: middle;
    }

    dt {
      width: 80%;
      padding: 0 0 0 10px;
    }

    dd {
      width: 20%;
    }
  }

  input {
    width: 100%;
    height: 40px;
    line-height: 40px;
    background-color: transparent;
    border: none;
    padding-left: 10px;
  }
}

.border__bottom {
  border-bottom: 1px solid #c8c8c8;
}

.border__top {
  border-top: 1px solid #c8c8c8;
}

select::-ms-expand {
  display: none;
}

dl.table__dl {
  display: table;
  width: 100%;

  dt,
  dd {
    display: table-cell;
    vertical-align: top;
  }

  &.middle {
    dt,
    dd {
      vertical-align: middle;
    }
  }
}

dl.toggle__dl {
  > dt {
    padding: 14px 20px;

    > p {
      img {
        margin-left: 35px;
      }
    }
  }

  > dd {
    display: none;
    background-color: #f0f0f0;
    transition: 0.3s ease all;

    &.show {
      display: block;
    }
  }
}

dl.goods__table {
  > dt {
    width: 90px;

    > img {
      width: 100%;
      max-width: 80px;
    }
  }
}

input {
  border: 1px solid $LIGHTGRAY;
  line-height: 44px;
  border-radius: 4px;
  padding: 0 10px;
}

.toggle__cart p.text__red.text__s,
.breadcrumb {
  display: none;
}

.breadcrumb__pd {
  padding-top: 80px;
}

.toggle__cart {
  &.show {
    display: flex;
  }
}

.error {
  background-color: #f0f0f0;
  border-radius: 4px;
  padding: 10px 20px;
}

.clear__sp {
  display: none;
}

.clear__pc {
  display: block;
}

@media screen and (min-width: 1036px) {
  main.reverse {
    -webkit-box-direction: row-reverse;
    -moz-box-direction: row-reverse;
    -webkit-flex-direction: row-reverse;
    -moz-flex-direction: row-reverse;
    flex-direction: row-reverse;
    display: flex;
  }

  .toggle__cart {
    display: block !important;
  }

  .toggle__cart p.text__red.text__s {
    display: inline-block;
  }
  .toggle__controller,
  .toggle__controller p.text__red.text__s {
    display: none;
  }

  .breadcrumb {
    padding-top: 56px;

    p i {
      margin: 0 5px;
    }
  }

  .clear__pc {
    display: none;
  }

  .clear__sp {
    display: block;
  }

  .clear__sp.inline {
    display: inline;
  }
}

input::placeholder {
  color: #c8c8c8;
}

@media screen and (max-width: 1035px) {
  .text__right.mg__top__s.text__left__pc button.btn.inline.square.yellow.text__s {
    padding: 0 10px;
  }
}

@media screen and (min-width: 1036px) {
  .login_page {
    margin-top: 90px;
  }
}

.login_page {
  margin-top: 75px;
}
